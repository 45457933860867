<template>
  <div>
    <!-- Error display -->
    <base-alert v-model="hasError" :dismissible="false" :type="alertType.error">
      <span v-html="currentError"></span>
    </base-alert>

    <!-- Progress display -->
    <progress-display v-if="isLoading">
      {{ loadingText }}
    </progress-display>

    <!-- File preview -->
    <iframe v-if="!isLoading" class="iframeClass" :src="pdfSrc"
      >Couldn't preview file</iframe
    >
  </div>
</template>

<script>
// design
import { alertType } from "@/design/components/alert/alertModel";

// model
import { recordType } from "@/model/record/recordModel";

// utils
import { createBlob } from "@/utils";

// services
import {
  canPreview,
  isCheckedOutByPrincipal,
  localFileExists
} from "@/services/record/recordService";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("document");

// mixins
import { baseComponentMixin } from "@/mixins/shared/base/component/baseComponentMixin";
import { downloadableMixin } from "@/mixins/shared/downloadable/downloadableMixin";
import { AnnotationOptionQueryModel } from "@/model/query/queryModel";
import { principalMixin } from "@/mixins/shared/user/principalMixin";

export default {
  name: "DocumentPreview",
  mixins: [baseComponentMixin, downloadableMixin, principalMixin],
  components: {
    BaseAlert: () => import("@/components/shared/base/BaseAlert"),
    ProgressDisplay: () =>
      import("@/components/shared/core/progress/ProgressDisplay")
  },
  props: {
    record: null,
    open: {
      type: Boolean,
      default: false
    },
    isViewOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pdfSrc: null,
      alertType: alertType
    };
  },
  methods: {
    ...mapActions({
      downloadPdfFileVersion: "downloadPdfFileVersion",
      downloadCompoundAsPdf: "downloadCompoundAsPdf",
      downloadEmptyRecordWipAsPdf: "downloadEmptyRecordWipAsPdf",
      downloadFileWipAsPdf: "downloadFileWipAsPdf",
      downloadFileVersionAsPdf: "downloadFileVersionAsPdf",
      downloadFileEditAsPdf: "downloadFileEditAsPdf",
      downloadImageFileVersionAsPdf: "downloadFileVersionAsPdf"
    }),

    /**
     * preview file
     * @return {Promise<void>}
     */
    async preview() {
      try {
        this.clearError();
        this.isLoading = true;

        if (!this.canPreview) {
          this.pdfSrc = undefined;
          return;
        }

        const id = this.record?.id ?? -1;
        const version = this.record?.version ?? -1;
        if (id < 0) {
          return;
        }

        // Download File as PDF document
        const type = this.record?.recordTypeId ?? -1;

        let response = undefined;

        switch (type) {
          case recordType.FILE: {
            if (localFileExists(this.record) && !this.isViewOnly) {
              const payload = {
                id: id,
                version: version,
                downloadOptions: AnnotationOptionQueryModel()
              };
              response = isCheckedOutByPrincipal(this.record, this.principal)
                ? await this.downloadFileEditAsPdf(payload)
                : await this.downloadFileWipAsPdf(payload);
            } else {
              const payload = {
                id: id,
                version: version,
                downloadOptions: null
              };
              response = await this.downloadImageFileVersionAsPdf(payload);
            }
            break;
          }
          case recordType.RECORD: {
            const payload = {
              id: id,
              version: 0,
              downloadOptions: AnnotationOptionQueryModel()
            };
            response = await this.downloadEmptyRecordWipAsPdf(payload);
            break;
          }
          case recordType.DOCUMENT: {
            response = await this.downloadCompoundAsPdf(
              this.createCompoundDocumentDownloadPayload(
                id,
                true,
                true,
                true,
                true,
                true,
                false
              )
            );
            break;
          }
        }

        if (!response) return;
        if (!response.data) return;

        const blob = createBlob(response.data);

        this.pdfSrc = window.URL.createObjectURL(blob);

        console.log(
          `${this.$options.name} preview() this.pdfSrc:`,
          this.pdfSrc
        );
      } catch (e) {
        console.error(e);
        this.currentError = e;
      } finally {
        this.isLoading = false;
      }
    }
  },
  computed: {
    /**
     * preview record id
     * @return {*|number}
     */
    recordId() {
      return this.record?.id ?? -1;
    },

    /**
     * loading Text
     * @return {string}
     */
    loadingText() {
      return `Generating file preview by record Id: ${this.recordId} ...`;
    },

    /**
     * compute whether current record's file(s) can be previewed
     * @return {boolean|*}
     */
    canPreview() {
      return canPreview(this.record);
    }
  },
  async created() {
    console.log(`${this.$options.name} created() recordId:`, this.recordId);

    if (this.open) {
      await this.preview();
    }
  },
  watch: {
    /**
     * watch when recordId of embedded document preview being change
     * @param newValue
     * @return {Promise<void>}
     */
    async recordId(newValue) {
      console.log(
        `${this.$options.name} watch() preview changed recordId: ${newValue}`,
        `open: ${this.open}`
      );

      if (this.open) {
        await this.preview();
      }
    },
    async open(newValue) {
      console.log(
        `${this.$options.name} watch() open changed recordId: ${newValue}`,
        `open: ${this.open}`
      );

      if (this.open) {
        await this.preview();
      }
    }
  }
};
</script>

<style>
/*
  One of solution, until embedded document viewer/preview is done
  this forces a min-height on the main views
*/
.iframeClass {
  min-height: 800px;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
